export const topBarHeight = 45
export const sideNavWidth = 220
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

export const base_url = 'https://apiv2.ixpressbd.com/api/'
export const business_name ='iXpress'
export const business_address ='Evergreen Plaza (4th floor), 260/B, Tejgaon, Industrial Area Dhaka - 1208, Bangladesh'
export const business_email ='support@ixpressbd.com'
export const business_web ='www.ixpressbd.com'
export const business_hotline ='(880) 2-9830376-78'
// content
export const footer_copyrights_text_right = ''
export const footer_copyrights_text_left =  'Copyrights © 2023 All Rights Reserved by iXpress';
export const site_name='iXpress';
 